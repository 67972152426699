import { Chat } from '../../types/notification/chat';
import { BETA_FEEDBACK_CHAT_ID, NOTIFICATION_CHAT_ID } from '../../utils/chat.util';
import Button from '../basic/button.component';
import { Comment, Subtitle } from '../typography.styled';
import ProfilePic from '../user/profile-pic.component';
import {
    MessageChatTopArea,
    MessageChatTopMessage,
    NotificationChatTopArea,
    NotificationTopMessage,
} from './messages.styled';

interface ChatTopProps {
    chat: Chat;
}
const ChatTop = ({ chat }: ChatTopProps) => {
    if (chat.chatId === NOTIFICATION_CHAT_ID) {
        return (
            <NotificationChatTopArea>
                <NotificationTopMessage>this is the start of your notification history</NotificationTopMessage>
            </NotificationChatTopArea>
        );
    }
    if (chat.chatId === BETA_FEEDBACK_CHAT_ID) {
        return (
            <NotificationChatTopArea>
                <NotificationTopMessage>submit comments and feedback about the app</NotificationTopMessage>
            </NotificationChatTopArea>
        );
    }
    if (!chat.user) {
        return (
            <NotificationChatTopArea>
                <NotificationTopMessage>
                    NOTE: chat functionality is still being worked on, as such, you may experience graphical errors.
                    additionally, only the 20 most recent messages are displayed
                </NotificationTopMessage>
            </NotificationChatTopArea>
        );
    }
    return (
        <MessageChatTopArea>
            <ProfilePic size="large" user={chat.user} />
            <Subtitle align="center" style={{ marginTop: '10px' }}>
                {chat.user.displayName}
            </Subtitle>
            <Comment align="center">@{chat.user.username}</Comment>
            <MessageChatTopMessage>
                NOTE: chat functionality is still being worked on, as such, you may experience graphical errors.
                additionally, only the 20 most recent messages are displayed
            </MessageChatTopMessage>
            <Button type="small" to={`/chat/${chat.chatId}/user/${chat.user.id}`}>
                View Profile
            </Button>
        </MessageChatTopArea>
    );
};

export default ChatTop;
