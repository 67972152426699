import { UserStub } from '../types';
import { Chat } from '../types/notification/chat';
import { Notification } from '../types/notification/notification';
import { NotificationType } from '../types/notification/notification-type';

export const BETA_FEEDBACK_CHAT_ID = 0;
export const ADMIN_CHAT_ID = -1;
export const NOTIFICATION_CHAT_ID = -2;

export const getChatTitle = (chat: Chat): string => {
    if (chat.chatId === BETA_FEEDBACK_CHAT_ID) return 'Beta Feedback';
    if (chat.chatId === ADMIN_CHAT_ID) return 'Oxchord Team';
    if (chat.chatId === NOTIFICATION_CHAT_ID) return 'Notifications';
    if (!chat.user) return 'Direct Message';
    return chat.user.displayName;
};

export const getToastTitle = (n: Notification): string => {
    switch (n.type) {
        case NotificationType.DirectMessage:
        case NotificationType.Admin:
            return 'MESSAGE';
        case NotificationType.Mentioned:
            return 'MENTION';
        case NotificationType.Repost:
            return 'REPOST';
        case NotificationType.SongUsed:
            return 'SONG';
        case NotificationType.SongCredit:
            return 'CREDIT';
        case NotificationType.PostLiked:
            return 'LIKE';
        case NotificationType.PostCommented:
            return 'COMMENT';
        case NotificationType.Follow:
            return 'FOLLOW';
    }
};

export const getMessage = (n: Notification, u?: UserStub): string => {
    const user = n.from?.displayName ?? u?.displayName ?? 'Someone';
    switch (n.type) {
        case NotificationType.DirectMessage:
        case NotificationType.Admin:
            return n.body ?? '';
        case NotificationType.Mentioned:
            return `${user} mentioned you in a post`;
        case NotificationType.Repost:
            return `${user} reposted your post`;
        case NotificationType.SongUsed:
            return `${user} used your song in a post`;
        case NotificationType.SongCredit:
            return `${user} credited you in a song`;
        case NotificationType.PostLiked:
            return `Your post was liked`;
        case NotificationType.PostCommented:
            return `${user} commented on your post`;
        case NotificationType.Follow:
            return `${user} started following you`;
    }
};
