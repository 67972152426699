import dayjs from 'dayjs';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import relativeTime from 'dayjs/plugin/relativeTime';
import { CurrentUserContextType } from '../../context/current-user.context';
import { Notification } from '../../types/notification/notification';
import { NotificationType } from '../../types/notification/notification-type';
import { getMessage } from '../../utils/chat.util';
import { SmallBody } from '../typography.styled';
import ProfilePic from '../user/profile-pic.component';
import {
    BubbleTimestamp,
    NotificationActionButton,
    NotificationActionButtonHolder,
    NotificationActionInfo,
    NotificationActionLink,
    NotificationBubbleArea,
    NotificationTextArea,
} from './messages.styled';

enum NotificationActionType {
    Button,
    Link,
    None,
}
interface NotificationAction {
    type: NotificationActionType;
    text: string;
    onClick?: () => void;
    to?: string;
}

interface NotificationBubbleProps {
    notification: Notification;
    currentUser: CurrentUserContextType;
}
const NotificationBubble = ({ notification, currentUser }: NotificationBubbleProps) => {
    const getActions = (): NotificationAction[] => {
        if (notification.type === NotificationType.Admin && notification.body?.startsWith('Beta feedback')) {
            return [
                {
                    type: NotificationActionType.Link,
                    text: 'view profile',
                    to: `/chat/${notification.chatId}/user/${notification.from?.id}`,
                },
                {
                    type: NotificationActionType.Link,
                    text: 'reply',
                    to: `/chat/${notification.from?.id}`,
                },
            ];
        }
        switch (notification.type) {
            case NotificationType.Follow:
                return [
                    {
                        type: NotificationActionType.Link,
                        text: 'view profile',
                        to: `/chat/${notification.chatId}/user/${notification.from?.id}`,
                    },
                    {
                        type: currentUser.isFollowingUser(notification.from?.id)
                            ? NotificationActionType.None
                            : NotificationActionType.Button,
                        text: currentUser.isFollowingUser(notification.from?.id)
                            ? 'you follow each other'
                            : 'follow back',
                        onClick: () => {
                            currentUser.toggleFollowing(notification.from?.id ?? -1);
                        },
                    },
                ];
        }
        return [];
    };

    const buildActionItem = (action: NotificationAction, index: number) => {
        if (action.type === NotificationActionType.Button) {
            return (
                <NotificationActionButton key={index} onClick={action.onClick}>
                    {action.text}
                </NotificationActionButton>
            );
        }
        if (action.type === NotificationActionType.Link) {
            return (
                <NotificationActionLink key={index} to={action.to ?? ''}>
                    {action.text}
                </NotificationActionLink>
            );
        }
        return <NotificationActionInfo key={index}>{action.text}</NotificationActionInfo>;
    };

    return (
        <>
            <BubbleTimestamp>{dayjs(notification.createdAt).fromNow().toLocaleLowerCase()}</BubbleTimestamp>
            <NotificationBubbleArea>
                <ProfilePic user={notification.from} size="small" />
                <NotificationTextArea>
                    <SmallBody>{getMessage(notification)}</SmallBody>
                    <NotificationActionButtonHolder>
                        {getActions().map((a, i) => buildActionItem(a, i))}
                    </NotificationActionButtonHolder>
                </NotificationTextArea>
            </NotificationBubbleArea>
        </>
    );
};

export default NotificationBubble;
