import { SmallBody, Comment } from '../typography.styled';
import ProfilePic from '../user/profile-pic.component';
import { BetaFeedbackListItemArea, BetaFeedbackListItemAreaInner, MessageListItemTextHolder } from './messages.styled';

const BetaFeedback = () => {
    return (
        <BetaFeedbackListItemArea to="/chat/0">
            <BetaFeedbackListItemAreaInner>
                <ProfilePic size="small" isAdminUser={true} />
                <MessageListItemTextHolder>
                    <SmallBody>Submit Beta Feedback</SmallBody>
                    <Comment>Tell us your thoughts on the app</Comment>
                </MessageListItemTextHolder>
            </BetaFeedbackListItemAreaInner>
        </BetaFeedbackListItemArea>
    );
};

export default BetaFeedback;
